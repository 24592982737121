.inner-content {
  padding: 50px 0;

  @include laptop {
    padding: 60px 0;
  }

  h1 {
    margin: 0 0 20px;
  }

  h2 {
    margin: 0 0 15px;
  }

  h3 {
    margin: 0 0 15px;
  }

  p, li {
    font-weight: 400;
  }

  a.btn, button.btn {
    margin: 0 0 10px;
    display: inline-block;
    width: 100%;

    @include phablet {
      width: auto;
    }
  }
}
