.featured-properties-section {

  h1 {
    margin: 0 0 20px;
  }

  .featured-properties-slider {
    margin: 10px -15px 30px !important;

    @include tablet {
      margin: 30px -15px 50px !important;
    }

    .property-block {
      padding: 0 15px;

      .image {
        display: block;
        position: relative;
        width: 100%;
        height: 200px;
        border: 2px solid rgba($brand-blue, .55);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center 35%;

        @include tablet {
          height: 250px;
        }

        &:before {
          content: 'View Property';
          color: $white;
          font-size: 22px;
          @include stretch;
          background-color: rgba($black, .5);
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 0;
          transition: $base-transition;
          z-index: 99;
        }

        &:hover:before {
          opacity: 1;
        }

        @include tablet {
          height: 300px;
        }
      }

      .flex-container {
        padding: 0 0 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @include laptop {
          flex-wrap: nowrap;
        }

        @include hd {
          padding: 0 15px 20px;
        }

        .left-block {
          padding: 20px 20px 10px;
          @include flex(0,0,100%);
          background-color: $white;
          border-color: $brand-blue;
          border-style: solid;
          border-top-width: 0;
          border-right-width: 1px;
          border-bottom-width: 2px;
          border-left-width: 1px;
          display: flex;
          flex-wrap: wrap;

          @include laptop {
            @include flex(0,0,140px);
            padding: 20px;
            border-right-width: 0;
          }

          p {
            @include flex(0,0,50%);
            margin: 0 0 10px;
            font-size: 18px;

            @include laptop {
              @include flex(0,0,100%);
              margin: 0 0 15px;
              font-size: 19px;
            }
          }
        }

        .right-block {
          padding: 20px 20px 5px;
          position: relative;
          background-color: $brand-blue;

          @include laptop {
            padding: 20px;
          }

          @include hd {
            padding: 20px 25px;
          }

          &:after {
            content: '';
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 20px;
            background-color: $brand-blue;
          }

          p, h3 {
            color: $white;
          }

          h3 {
            margin: 0 0 20px;
          }

          p {
            font-weight: 600;
            font-size: 18px;

            @include laptop {
              font-size: 19px;
            }
          }

          .read-more {
            display: none;
          }
        }
      }
    }
  }

  .slider-nav {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    display: inline-block;

    .slick-arrow {
      position: absolute;
      top: 0;
      width: 27px;
      height: 27px;
      padding: 0;
      border-radius: 50%;
      border: 2px solid $black;
      z-index: 1000;
      cursor: pointer;
      transition: $base-transition;
      background-color: $black;

      &:hover {
        background-color: $brand-blue;
        border-color: $brand-blue;
      }

      &:before {
        color: $off-white;
        font-family: FontAwesome;
        font-size: 26px;
        line-height: 23px;
        vertical-align: middle;
        position: absolute;
        transition: $base-transition;
      }

      &.arrow-left {
        left: -35px;

        &:before {
          left: 6px;
          content: '\f104';
        }
      }

      &.arrow-right {
        right: -35px;

        &:before {
          right: 6px;
          content: '\f105';
        }
      }
    }

    .slick-dots {
      position: relative;
      top: -7px;
    }
  }
}


.residential-properties-section,
.commercial-properties-section {
  padding: 0;

  h1 {
    margin: 0 0 30px;
  }

  h1, p {
    color: $white;
  }

  .content {
    padding: 40px 0 50px;

    @include laptop {
      padding: 100px 0;
    }
  }

  .featured-image {
    display: block;
    width: 100%;
    height: 250px;

    @include laptop {
      overflow: hidden;
      position: absolute;
      top: 0;
      bottom: 0;
      width: calc(50% + 30px);
      height: calc(100% + 25px);
      z-index: 1000;
      transform: skewX(-15deg);
    }

    .image {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;

      @include laptop {
        width: auto;
        height: auto;
        transform: skewX(15deg);
        @include stretch(-100px);
      }
    }
  }

}


.residential-properties-section {
  background-color: $brand-primary;

  .featured-image {
    right: -100px;
    top: -25px;

    .image {
      background-image: url('../images/bg_residential.jpg');
      background-position: right top;
    }
  }

  .search-big-towns {
      .search-big-town {
          display: inline;

          &:nth-of-type(1n+2) {
              &:before {
                  content: "\f111";
                  padding: 0 8px 0 10px;
                  color: #fff;
                  font-family: "FontAwesome";
                  font-size: 5px;
                  vertical-align: middle;
              }
          }
      }
  }
}


.commercial-properties-section {
  background-color: $brand-blue;

  .featured-image {
    left: -100px;
    bottom: -25px;

    .image {
      background-image: url('../images/bg_commercial.jpg');
      background-position: bottom;
    }
  }
}
