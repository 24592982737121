.banner {

  .nav-container {
    position: relative;

    &.top {
      padding: 5px 0;
      background-color: $brand-primary;

      @include laptop {
        padding: 0;
      }

      @include desktop {
        background-color: $white;
      }

      .logo {
        z-index: 1000;
        max-width: 160px;

        @include desktop {
          max-width: 198px;
          left: 15px;
          top: 5px;
          position: absolute;
        }

        @include wide {
          top: 12px;
        }

        @include hd {
          left: 0;
        }
      }
    }

    &.primary {
      background-color: $brand-primary;
      border-bottom: 4px solid $white;
    }
  }


  .navigation {
    list-style-type: none;
    margin: 0 -20px 0 0;
    padding: 0;
    text-align: right;

    li {
      display: inline-block;

      a {
        display: block;
        font-size: 15px;
        line-height: 15px;
        font-weight: 700;
        text-transform: uppercase;
        padding: 15px 18px;

        @include wide {
          font-size: 17px;
          line-height: 17px;
        }
      }

      &.menu-item-has-children {
        position: relative;

       > a {
          position: relative;

          &:after {
            font-family: FontAwesome;
            content: '\f107';
            margin-left: 10px;
            vertical-align: top;
          }
        }

        &:hover {

          .sub-menu {
            max-height: 1000px;
            transition: .9s;
          }
        }

        .sub-menu {
          padding: 0;
          margin: 0;
          position: absolute;
          top: 100%;
          left: 0;
          z-index: 1000;
          text-align: left;
          background-color: $brand-primary;
          max-height: 0;
          overflow: hidden;
          transition: .3s;

          li {
            display: block;

            a {
              display: block;
              white-space: nowrap;
            }
          }
        }
      }
    }

    &.top-nav {

      @include wide {
        margin-right: -22px;
      }

      li {
        a {
          color: $black;

          @include wide {
            padding: 15px 22px;
          }
        }

        a:hover, &.current-menu-item a {
          background-color: $off-white;
        }

        &.phone a:hover {
          background-color: transparent;
        }
      }
    }

    &.primary-nav {

      @include wide {
        margin-right: -25px;
      }

      li {
        a {
          color: $white;

          @include wide {
            padding: 15px 25px;
          }
        }

        a:hover, &.current-menu-item a {
          background-color: darken($brand-primary, 5%);
        }
      }
    }
  }
}


.hero {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;

  @include hd {
    min-height: 842px;
  }

  &:before {
    content: '';
    @include stretch;
    background-color: rgba($black, .25);
  }

  .cta {
    padding: 80px 0;
    text-align: center;

    @include mobile {
      padding: 100px 0;
    }

    @include tablet {
      text-align: left;
    }

    @include laptop {
      padding: 150px 0;
    }

    @include hd {
      padding: 195px 0;
    }

    p {
      color: $white;
      text-shadow: 1px 1px 1.96px rgba(0, 0, 0, 1);
    }

    .heading {
      font-size: 38px;
      line-height: 33px;
      font-weight: 700;
      margin: 0 0 20px;

      @include mobile {
        font-size: 44px;
        line-height: 38px;
      }

      @include laptop {
        font-size: 65px;
        line-height: 60px;
      }
    }

    .content {
      font-size: 19px;
      line-height: 19px;
      font-weight: 600;
      margin: 0 0 25px;

      @include mobile {
        font-size: 21px;
        line-height: 21px;
      }

      @include laptop {
        font-size: 24px;
        line-height: 24px;
      }
    }

    .btn {
      margin: 0 0 10px;
      width: 100%;

      @include tablet {
        margin: 0 10px 0 0;
        width: auto;
      }
    }
  }

  .strapline {
    padding: 30px 0;
    background-color: rgba($dark-gray, .7);
    width: 100%;

    @include laptop {
      padding: 55px 0;
    }

    @include hd {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    p {
      text-align: center;
      color: $white;
      margin: 0;
      font-size: 20px;
      line-height: 23px;
      font-weight: 400;

      @include laptop {
        padding: 0 50px;
        font-size: 25px;
        line-height: 28px;
      }
    }
  }

  &.interior-hero {
    min-height: auto;

    .cta {
      padding: 60px 0;

      @include mobile {
        padding: 80px 0;
      }

      @include laptop {
        padding: 120px 0;
      }

      @include hd {
        padding: 150px 0;
      }
    }
  }

  &.no-hero {
    display: none;
  }
}
