.archive-filters {
  display: block;
  margin: 0 0 30px;

  .filter-block {
    margin: 0 0 20px;

    .filter-select {
      width: 100%;
      background-color: $white;
      border: 1px solid lighten($gray, 20%);
    }

    .min-max-block {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;

      .filter {
        @include flex(0,0,50%);
        padding: 0 10px;

        input {
          padding: 5px 10px;
          width: 100%;
          border: 1px solid lighten($gray, 20%);
        }
      }
    }

    .filter-list {
      margin: 0;
      padding: 0;
      list-style-type: none;

      li {
        display: inline-block;
        background-color: $off-white;
        padding: 5px 10px;
        margin-bottom: 4px;

        input {
          margin-right: 5px;
        }

        label {
          margin: 0;
        }
      }
    }
  }
}
