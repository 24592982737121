.breadcrumbs {
  display: block;
  padding: 11px 10px;
  margin: 0 0 30px;
  background-color: $off-white;
  font-size: 14px;
  font-weight: 400;

  @include phablet {
    font-size: 16px;
    margin: 0 0 40px;
  }

  a {
    border: 0;
  }

  span[property="itemListElement"] {
    margin: 0 3px;

    @include phablet {
      margin: 0 5px;
    }
  }
}


.property-listing {
  margin: 0;

  .property-gallery {

    .lslide {
      @include aspect-ratio(4,3);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;

      &:after {
        content: 'View Gallery';
        color: $white;
        font-size: 26px;
        @include stretch;
        background-color: rgba($black, .5);
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transition: $base-transition;
      }

      &:hover:after {
        opacity: 1;
      }

      img {
        display: none;
      }
    }

  }

  .lSSlideOuter {
    .lSPager {

      li {
        position: relative;
        cursor: pointer;
        max-height: 74px;
        overflow: hidden;

        &:before {
          content: '';
          @include stretch;
          background-color: rgba($black, .5);
          opacity: 0;
          transition: $base-transition;
        }

        &.active, &:hover {
          border-radius: 0;

          &:before {
            opacity: 1;
          }
        }
      }
    }
  }

  .lSAction {
    a {
      opacity: 1;
    }
  }

  .property-info {
    margin: 0 0 30px;
    height: 100%;
  }

  .listing-info {
    margin: 30px 0 0;

    @include laptop {
      margin: 0;
      height: 100%;
    }
  }

  .property-details {
    margin: 0 0 30px;

    .property-detail-list {
      column-count: 1;
      list-style-type: none;
      padding: 0;
      margin: 0;

      @include mobile {
        column-count: 2;
      }

      @include tablet {
        column-count: 3;
      }

      @include laptop {
        column-count: 4;
      }

      li {
        margin: 0 0 5px;
      }
    }
  }
}


.property-card {

  .image-container {
    position: relative;
    display: block;
    margin: 0 0 15px;

    @include desktop {
      margin: 0;
    }

    &:before {
      content: 'View Property';
      color: $white;
      font-size: 22px;
      @include stretch;
      background-color: rgba($black, .5);
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: $base-transition;
      z-index: 99;
    }

    &:hover:before {
      opacity: 1;
    }

    .featured-image {
      @include aspect-ratio(4,3);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center 25%;
    }
  }

  h2.entry-title {
    a {
      color: $black;

      &:hover {
        color: $brand-primary;
      }

      .location-title {
        color: $brand-primary;
      }
    }
  }


  .attributes {
    margin: 0 0 10px;

    .attribute {
      display: inline-block;
      font-size: 18px;
      padding: 3px 10px 4px;
      background-color: $gray;
      color: $white;
      margin: 0 3px 6px 0;

      &.blue {
        background-color: $brand-blue;
        color: $white;
      }
    }
  }

  .read-more {
    display: none;
  }
}

.property-map {

  #map_canvas {
    width: 100%;
    height: 300px;

    @include tablet {
      height: 460px;
    }
  }
}

.paged-text {
  display: block;
  margin: 0 0 20px;

  span {
    display: inline-block;
  }

  .total-posts {
    float: right;
  }
}


.primary_pagination {
  display: block;
  margin: 15px 0 0;

  .current, a {
    padding: 8px 18px;
    font-size: 18px;
  }

  .current {
    background-color: $brand-primary;
    color: $white;
  }

  a {
    display: inline-block;
    background-color: $off-white;

    &:hover {
      background-color: $brand-primary;
      color: $white;
    }
  }
}
