html, body {
  font-size: $body-font-size;
  font-weight: $body-font-weight;
  font-family: $body-font;
  color: $body-color;
  @include smooth-fonts;
  max-width: 100%;
  overflow-x: hidden;
}

img {
  max-width: 100%;
  height: auto;
}

h1, .h1 {
  color: $heading-color;
  font-family: $heading-font;
  font-weight: $h1-weight;
  font-size: $h1-size;
  text-transform: uppercase;
}

h2, .h2 {
  color: $heading-color;
  font-family: $heading-font;
  font-weight: $h2-weight;
  font-size: $h2-size;
  text-transform: uppercase;
}

h3, .h3 {
  color: $heading-color;
  font-family: $heading-font;
  font-weight: $h3-weight;
  font-size: $h3-size;
  text-transform: uppercase;
}


h4, .h4 {
  color: $heading-color;
  font-family: $heading-font;
  font-weight: $h4-weight;
  font-size: $h4-size;
  text-transform: uppercase;
}


p, li {
  font-family: $body-font;
  font-size: $body-font-size;
  font-weight: $body-font-weight;
  line-height: 22px;
}

a {
  transition: $base-transition;
  &:hover, &:focus {
    text-decoration: none;
  }
}

ul.nav {
  list-style-type: none;
  padding: 0;

  .sub-menu {
    list-style-type: none;
    padding: 0;
  }
}

ul.list-three-col {
  column-count: 1;

  @include phablet {
    column-count: 2;
  }

  @include laptop {
    column-count: 3;
  }
}

.panel {
  position: relative;
  padding: $section-padding 0;
  background-color: $off-white;

  @include tablet {
    padding: ($section-padding + 10px) 0;
  }
  @include laptop {
    padding: ($section-padding + 15px) 0;
  }

  h1 {
    margin: 0 0 40px;
  }
}

.card-block {
  display: block;
  margin: 0 0 20px;
  padding: 20px;
  background-color: $off-white;

  @include desktop {
    padding: 30px;
  }

  &.light {
    background-color: transparent;
    border: 2px solid $brand-primary;
  }
}

.pill-block {
  display: inline-block;
  padding: 8px 18px;
  background-color: $off-white;
}



.slick-dots {

  li  {
    display: inline-block;
    width: auto;
    margin: 0;
    padding: 0 4px;

    button {
      padding: 4px;
      width: auto;
      height: auto;
      border: 5px solid $black;
      border-radius: 50%;
      transition: $base-transition;

      &:before {
        display: none;
      }
    }

    &:hover button, &.slick-active button {
      border-color: $brand-blue;
    }
  }
}


.lSAction>a {
   background-image: url("../images/controls.png");
}

.lg-outer .lg-has-vimeo .lg-video-play {
  background: url("../images/vimeo-play.png") no-repeat scroll 0 0 transparent;
}
.lg-outer .lg-has-vimeo:hover .lg-video-play {
  background: url("../images/vimeo-play.png") no-repeat scroll 0 -58px transparent;
}
.lg-outer .lg-has-html5 .lg-video-play {
  background: transparent url("../images/video-play.png") no-repeat scroll 0 0;
}
.lg-outer .lg-has-youtube .lg-video-play {
  background: url("../images/youtube-play.png") no-repeat scroll 0 0 transparent;
}
.lg-outer .lg-has-youtube:hover .lg-video-play {
  background: url("../images/youtube-play.png") no-repeat scroll 0 -60px transparent;
}
.lg-outer .lg-item {
  background: url("../images/loading.gif") no-repeat scroll center center transparent;
}


@mixin make-col-offset($size, $columns: $grid-columns) {
  margin-left: percentage($size / $columns);
}

@mixin make-col-push($size, $columns: $grid-columns) {
  left: if($size > 0, percentage($size / $columns), auto);
}

@mixin make-col-pull($size, $columns: $grid-columns) {
  right: if($size > 0, percentage($size / $columns), auto);
}

@mixin make-col-modifier($type, $size, $columns) {
  // Work around the lack of dynamic mixin @include support (https://github.com/sass/sass/issues/626)
  @if $type == push {
    @include make-col-push($size, $columns);
  } @else if $type == pull {
    @include make-col-pull($size, $columns);
  } @else if $type == offset {
    @include make-col-offset($size, $columns);
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $modifier in (pull, push) {
      @for $i from 0 through $grid-columns {
        .#{$modifier}#{$infix}-#{$i} {
          @include make-col-modifier($modifier, $i, $grid-columns)
        }
      }
    }
    @for $i from 0 through $grid-columns {
      @if not ($infix == "" and $i == 0) { // Avoid emitting useless .offset-xs-0
        .offset#{$infix}-#{$i} {
          @include make-col-modifier(offset, $i, $grid-columns)
        }
      }
    }
  }
}
