// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}


.ui-datepicker {
  background-color: $off-white;
  border: 1px solid $gray;
  padding: 20px;

  .ui-state-default {
    padding: 5px;
  }
}

.gform_wrapper {

  .gf_progressbar {
    margin: 0 0 20px;
  }

  .validation_error {
    margin: 0 0 10px;
  }

  .validation_message, .validation_error {
    color: $red;
    clear: both;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  .gsection_title {
    display: block;
    @extend .h3;
    background-color: $brand-blue;
    color: $white;
    padding: 10px;
    clear: both;
  }

  .spacer {
    display: block;
    width: 100%;
    height: 1px;
    background-color: $gray;
    margin: 0 0 15px;
  }

  h3 {
    clear: both;
    border-bottom: 1px solid $gray;
    padding: 15px 0 10px;
    margin: 0 0 20px;
    @extend .h4;
    font-weight: 700;
  }

  .button {
    @extend a.btn;
    @extend .primary;
    cursor: pointer;
  }

  .ginput_container_address {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
    clear: both;
    justify-content: space-between;

    .address_line_1 {
      @include flex(0,0,100%);
      margin-bottom: 15px;
      padding: 0 8px;
    }

    .address_city, .address_state, .address_zip {
      @include flex(0,0,100%);
      padding: 0 8px;

      @include tablet {
        @include flex(0,0,33%);
      }
    }
  }

  .gfield_radio {


    li {
      display: inline-block;
      margin-right: 30px;
    }
  }


  .gfield {
    display: block;
    margin-bottom: 20px;
    clear: both;

    @include tablet {
      &.left_half, &.right_half {
        width: calc(50% - 8px);
        clear: none;
      }

      &.left_third, &.middle_third, &.right_third {
        width: calc(33% - 8px);
        clear: none;
      }

      &.left_half, &.left_third, &.middle_third {
        float: left;
      }

      &.right_half, &.right_third {
        float: right;
      }

      &.middle_third {
        margin-left: 16px;
      }
    }

    &.captcha label {
      display: none;
    }

    .gf_clear {
      clear: both;
    }

    label {
      font-weight: 800;
      text-transform: uppercase;
    }

    input,
    textarea {
      border: 1px solid lighten($gray, 20%);
      padding: 12px 10px;
      width: 100%;
      font-size: 19px;
    }

    textarea {
      height: 180px;
    }
  }

  .gform_footer {

    input[type=submit] {
      -webkit-appearance: none;
      -moz-appearance: none;
      @extend a.btn;
      @extend .primary;
      cursor: pointer;
    }
  }

  .g-recaptcha label {
    @extend .sr-only;
  }
}
