.sideNav {
  width: $sideNavWidth;
  #{$sideNavDirection}: -$sideNavWidth;
  position: fixed;
  z-index: 1000;
  height: 100%;
  top: 0;
  background: $brand-primary;
  transition: all 0.3s ease;
  box-shadow: inset 26px 0px 119px -68px rgba(0,0,0,0.75);

  a {
    display: block;
    color: #fff;
    font-size: 16px;
    border-bottom: 1px solid darken($brand-primary, 3%);
    padding: 17px 20px;
  }

  &.sideNav-open {
    #{$sideNavDirection}: 0px;
  }

  .menu-primary-navigation,
  .menu-top-navigation {

    a.dropdown-toggle:after {
      vertical-align: middle;
    }
  }

  .dropdown-menu {
    transform: none !important;
    position: relative !important;
    float: none;
    padding: 0;
    margin: 0;
    border: 0;

    li {
      a {
        color: $brand-primary;
        border-color: darken($brand-primary, 5%);
      }
    }
  }
}

/* classes applied to the body */
.sideNavBody {
    position: relative;
    #{$sideNavDirection}: 0;
    transition: all 0.3s ease;
}

.sideNavBody-push {
    #{$sideNavDirection}: $sideNavWidth;
}
