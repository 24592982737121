.home {
  .testimonials {

    @include laptop {
      padding: 100px 0 75px;
    }
  }
}

.testimonials {
  text-align: center;

  @include laptop {
    padding: 75px 0;
  }

  .testimonial-slider {

    @include tablet {
      padding: 0 60px;
      margin: 0 25px;
    }

    .testimonial {
      padding: 0 15px;

      @include tablet {
        padding: 0 45px;
      }

      .content {
        font-weight: 400;
      }

      .author {
        font-size: 22px;
        font-weight: 600;

        @include tablet {
          font-size: 24px;
        }

        &:before {
          display: block;
          content: url('../images/quote.png');
          margin: 0 0 20px;
        }
      }
    }

    .slick-dots {
      position: absolute;
      bottom: -30px;
    }
  }

  .slick-arrow {
    position: absolute;
    top: 25%;
    transform: translateY(-50%);
    display: block !important;
    width: 44px;
    height: 44px;
    padding: 0;
    border-radius: 50%;
    border: 2px solid $black;
    z-index: 1000;
    cursor: pointer;
    transition: $base-transition;

    &:hover {
      background-color: $black;

      &:before {
        color: $off-white;
      }
    }

    &:before {
      font-family: FontAwesome;
      font-size: 36px;
      line-height: 38px;
      vertical-align: middle;
      position: absolute;
      transition: $base-transition;
    }

    &.arrow-left {
      left: 0;

      &:before {
        left: 12px;
        content: '\f104';
      }
    }

    &.arrow-right {
      right: 0;

      &:before {
        right: 12px;
        content: '\f105';
      }
    }
  }
}


.footer-cta {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  @extend .hero;
  min-height: auto;

  &:before {
    display: none;
  }

  .cta {
    padding: 50px 0;

    @include laptop {
      padding: 170px 0;
    }

    p {
      color: $black;
      text-shadow: none;
    }
  }
}


.footer {
  padding: 30px 0;
  background-color: $brand-blue;
  text-align: center;

  @include tablet {
    padding: 30px 0 40px;
  }

  .logo {
    margin: 0 0 10px;

    @include tablet {
      margin: 0 0 5px;
    }
  }

  .social-links {
    display: block;
    margin: 0 0 15px;
    font-size: 24px;
  }

  p, a {
    color: $white;
    font-weight: 500;
  }

  .footer-nav {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      display: block;
      margin: 4px 0;

      @include tablet {
        display: inline-block;
        margin: 0;
      }

      &:after {
        display: none;
        content: '|';
        color: $white;
        margin: 0 5px;

        @include tablet {
          display: inline-block;
        }
      }

      a {
        color: $white;

        &:hover {
          color: $gray;
        }
      }

      &:last-child:after {
        display: none;
      }
    }
  }
}


.linksCopy {
  padding: 8px 0;
  background-color: #0284a7;
  text-align: center;

  p, a {
    color: $white;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
  }

  .left-text {

    @include laptop {
      text-align: left;
    }
  }

  .right-text {

    @include laptop {
      text-align: right;
    }
  }
}
